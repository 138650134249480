import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				O nas | Odkryj Spin & Groove Vinyls
			</title>
			<meta name={"description"} content={"Gdzie każdy obrót to historia"} />
			<meta property={"og:title"} content={"O nas | Odkryj Spin & Groove Vinyls"} />
			<meta property={"og:description"} content={"Gdzie każdy obrót to historia"} />
			<meta property={"og:image"} content={"https://zepifyplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zepifyplay.com/img/12335667.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zepifyplay.com/img/12335667.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zepifyplay.com/img/12335667.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zepifyplay.com/img/12335667.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zepifyplay.com/img/12335667.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zepifyplay.com/img/12335667.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				O nas
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Nasza historia
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Spin & Groove Vinyls zaczęło się od prostego pomysłu: stworzenia przystani dla miłośników muzyki, w której można by celebrować i dzielić się bogatymi, ciepłymi dźwiękami winyli. To, co zaczęło się jako skromna kolekcja klasycznych płyt, stało się ukochanym miejscem zarówno dla entuzjastów, jak i nowicjuszy. Nasza podróż opiera się na pasji do namacalnej formy muzyki, radości z odkrywania nowych dźwięków i społeczności, która tworzy się wokół wspólnych doświadczeń muzycznych.
					</Text>
				</Box>
				<Image src="https://zepifyplay.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://zepifyplay.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Nasza kolekcja
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Sercem Spin & Groove jest nasza zróżnicowana i stale powiększająca się kolekcja płyt winylowych. Obejmująca dekady i gatunki, każdy album w naszym sklepie został starannie wybrany, aby zapewnić wybór tak wyjątkowy, jak nasi goście. Od kultowych albumów, które zdefiniowały epoki, po mało znane wydawnictwa czekające na ponowne odkrycie, nasze półki są skarbnicą dla ciekawskich umysłów i doświadczonych kolekcjonerów.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Dlaczego warto wybrać Spin & Groove?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Wyjątkowe doświadczenie
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Zanurz się w dotykowej radości przeglądania winyli, czując w dłoniach ciężar historii i artyzmu.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Jakość i dbałość
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Dokładamy wszelkich starań, aby zapewnić jakość naszych płyt, od skrupulatnego sortowania po odpowiednie przechowywanie, zapewniając najlepsze możliwe wrażenia słuchowe.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Porady dźwiękowe
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Nasz personel to nie tylko pracownicy - to zapaleni kolekcjonerzy i miłośnicy muzyki, którzy chętnie dzielą się swoją wiedzą i rekomendacjami.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Duch społeczności
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Spin & Groove to nie tylko sklep - to centrum społeczności, w którym miłośnicy muzyki gromadzą się, aby dzielić się swoją pasją, historiami i odkryciami.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});